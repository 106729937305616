<template>
    <el-upload
        ref="uploadFileRef"
        style="display: inline; margin-left: 10px"
        action="#"
        :show-file-list="true"
        :multiple="false"
        :limit="limit"
        :accept="acceptFileType(fileType)"
        :before-upload="
            (file) => {
                return beforeUpload(file, fileType);
            }
        "
        :http-request="
            (param) => {
                return uploadFile(param);
            }
        "
        :on-error="uploadError"
        :on-success="uploadSuccess"
        :on-remove="removeUploadFile"
        :on-change="uploadChange"
    >
        <el-button slot="trigger" size="mini">选取文件</el-button>
    </el-upload>
</template>

<script>
import AdvertUtils, { AdvertFileUploadUrl } from 'js/AdvertUtils';

export default {
    name: 'AdvertUpload',
    model: {
        prop: 'uploadFiles',
        event: 'change',
    },
    props: {
        /*v-model，用于双向绑定上传的文件列表*/
        uploadFiles: {
            type: Array,
            default: () => [],
        },
        /*广告文件类型*/
        fileType: {
            type: Number,
            default: 0,
        },
        /*上传数量限制*/
        limit: {
            type: Number,
            default: 1,
        },
    },
    data() {
        return {
            uploadFileArr: [],
        };
    },
    watch: {
        uploadFiles: {
            handler(newUploadFiles, o) {
                this.uploadFileArr = newUploadFiles || [];
                this.$nextTick(() => {
                    this.$refs.uploadFileRef.uploadFiles = newUploadFiles || [];
                });
            },
            immediate: true,
        },
        uploadFileArr: {
            handler(newUploadFileArr, o) {
                this.$emit(
                    'change',
                    newUploadFileArr,
                    /*上传文件后生成的url地址*/
                    (newUploadFileArr || []).map((file) => new AdvertFileUploadUrl(file?.response?.advertFileUrl))
                );
            },
            immediate: true,
        },
    },
    methods: {
        acceptFileType(fileType) {
            return AdvertUtils.acceptFileType(fileType);
        },
        beforeUpload(file, fileType) {
            return AdvertUtils.beforeUpload(file, fileType);
        },
        uploadFile(param) {
            return AdvertUtils.uploadFile(param);
        },
        uploadChange(file, fileList) {
            this.uploadFileArr = JSON.parse(JSON.stringify(fileList));
        },
        uploadError(err, file, fileList) {
            this.$message.warning('上传失败：' + err);
            this.uploadFileArr = JSON.parse(JSON.stringify(fileList));
        },
        uploadSuccess(response, file, fileList) {
            this.$message.success('上传成功');
            this.uploadFileArr = JSON.parse(JSON.stringify(fileList));
        },
        removeUploadFile(file, fileList) {
            this.uploadFileArr = JSON.parse(JSON.stringify(fileList));
        },
    },
};
</script>
