<template>
    <div class="AdvertEdit">
        <el-card shadow="never" style="margin-top: 8px">
            <el-page-header @back="goBack" />
        </el-card>
        <el-form @submit.native.prevent :model="advert" :rules="rules" ref="updateForm" label-width="80px" size="small">
            <el-row>
                <el-col :span="12">
                    <el-card shadow="never" style="margin-top: 8px">
                        <el-row>
                            <el-form-item label="编码">
                                <span>{{ advert.code }}</span>
                            </el-form-item>
                            <el-form-item label="客户名称" prop="customerCode">
                                <span> {{ customerName(advert.customerCode) }}</span>
                            </el-form-item>
                            <el-form-item label="广告名称" prop="name">
                                <el-input v-model="advert.name" :maxlength="50" />
                            </el-form-item>
                            <el-form-item label="广告类型" prop="type">
                                <el-select v-model="advert.type" size="mini" @change="changeAdvertFileType">
                                    <el-option :value="imgFileType" label="图片" />
                                    <el-option :value="videoFileType" label="视频" />
                                </el-select>
                            </el-form-item>
                            <el-alert
                                title="1.文件名过长无法找到删除按钮时，可以使用DELETE键进行删除；2.如需重新上传文件，需要先把上传文件列表中的文件删除，然后再进行上传"
                                type="warning"
                                show-icon
                                center
                            />
                            <el-form-item label="文件内容" prop="uploadAccessory" style="width: 80%">
                                <!--用于校验提示-->
                                <el-input v-model="advert.uploadAccessory" style="display: none" />
                                <advert-upload
                                    :file-type="advert.type"
                                    v-model="advert.uploadFiles"
                                    :limit="1"
                                    @change="changeUploadFiles"
                                />
                            </el-form-item>
                            <el-form-item label="跳转类型" prop="jumpType">
                                <el-select
                                    v-model="advert.jumpType"
                                    size="mini"
                                    :disabled="!advert.enableJumpType"
                                    @change="() => changeJumpType(advert)"
                                >
                                    <el-option label="不跳转" :value="1" />
                                    <el-option label="打开大图" :value="2" />
                                    <el-option label="跳转外链" :value="3" />
                                </el-select>
                            </el-form-item>
                            <el-form-item
                                label="跳转内容"
                                prop="jumpLink"
                                style="width: 80%"
                                :rules="advert.jumpType === 1 ? [{ required: false }] : rules.jumpLink"
                            >
                                <div v-if="advert.jumpType === 2">
                                    <el-input v-model="advert.jumpLink" style="display: none" />
                                    <advert-upload
                                        :file-type="imgFileType"
                                        v-model="advert.jumpFiles"
                                        :limit="1"
                                        @change="
                                            (uploadFileArr, uploadFileUrlArr) =>
                                                changeJumpFiles(uploadFileArr, uploadFileUrlArr, advert)
                                        "
                                    />
                                </div>
                                <div v-else>
                                    <el-input
                                        :disabled="advert.jumpType === 1"
                                        v-model="advert.jumpLink"
                                        :maxlength="200"
                                    />
                                </div>
                            </el-form-item>
                            <el-form-item label="广告内容" prop="content">
                                <el-input v-model="advert.content" :maxlength="500" />
                            </el-form-item>
                            <el-form-item label="广告时长" prop="timeLength">
                                <el-select v-model="advert.timeLength" size="mini">
                                    <el-option :value="15" label="15秒" />
                                    <el-option :value="30" label="30秒" />
                                </el-select>
                            </el-form-item>
                            <el-button type="primary" @click="handleSave" size="small">保存</el-button>
                        </el-row>
                    </el-card>
                </el-col>
                <el-col :span="12">
                    <b>预览</b>
                    <template v-if="advert.uploadAccessory">
                        <img v-if="isImgAdvertFileType()" :src="advert.uploadAccessory" class="image" />
                        <video
                            v-else-if="isVideoAdvertFileType()"
                            :src="advert.uploadAccessory"
                            controls
                            autoplay
                            class="video"
                        />
                    </template>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import UrlUtils from '../../../../js/UrlUtils';
import AdvertUtils, { AdvertFileUploadUrl } from '../../../../js/AdvertUtils';
import AdvertUpload from 'views/menu/advert/manage/AdvertUpload';

export default {
    name: 'AdvertEdit',
    components: { AdvertUpload },
    props: ['form'],
    data() {
        return {
            imgFileType: AdvertUtils.FileTypeEnum.IMG,
            videoFileType: AdvertUtils.FileTypeEnum.VIDEO,
            advert: {
                code: '',
                name: '',
                customerCode: '',
                type: -1,
                uploadAccessory: '',
                content: '',
                timeLength: '',
                uploadFiles: [],
                jumpFiles: [],
                jumpLink: '',
                jumpType: 1,
                enableJumpType: true,
            },
            rules: {
                name: { required: true, message: '请填写广告名称', trigger: 'change' },
                customerCode: { required: true, message: '请选择客户', trigger: 'change' },
                type: { required: true, message: '请选择广告类型', trigger: 'change' },
                uploadAccessory: { required: true, message: '请上传文件', trigger: ['change', 'blur'] },
                content: { required: true, message: '请填写广告内容', trigger: 'change' },
                timeLength: { required: true, message: '请选择广告时长', trigger: 'change' },
                jumpLink: { required: true, message: '跳转内容不能为空', trigger: 'change' },
            },
            customers: [],
        };
    },
    mounted() {
        this.advert = JSON.parse(JSON.stringify(this.form));
        // 如果是图片 让跳转类型可用
        this.advert.enableJumpType = this.advert.type === this.imgFileType;
        //根据广告url地址生成已上传的广告文件列表
        this.advert.uploadFiles = [
            {
                uid: Date.now(),
                status: 'success',
                name: this.advert.uploadAccessory,
                response: { advertFileUrl: this.advert.uploadAccessory },
            },
        ];
        // 如果是跳转大图 则生成已上传的广告文件列表
        if (this.advert.jumpType === 2) {
            this.advert.jumpFiles = [
                {
                    uid: Date.now(),
                    status: 'success',
                    name: this.advert.jumpLink,
                    response: { advertFileUrl: this.advert.jumpLink },
                },
            ];
        }
        UrlUtils.QueryRemote(this, '/advert/customer/list', (rst) => {
            this.customers = rst;
        });
    },
    methods: {
        customerName(code) {
            return (this.customers || []).find((c) => c.code === code)?.name;
        },
        changeAdvertFileType() {
            //切换广告文件类型，清空上传文件列表
            this.advert.uploadFiles = [];
            this.advert.uploadAccessory = '';
            // 切换为图片时 选择跳转类型为可用
            this.advert.enableJumpType = this.advert.type === this.imgFileType;
            // 切换为视频时清空跳转内容
            if (!this.advert.enableJumpType) {
                this.advert.jumpType = 1;
                this.advert.jumpLink = '';
            }
        },
        changeJumpFiles(uploadFileArr = [], uploadFileUrlArr = [], row) {
            row.jumpFiles = uploadFileArr;
            //拼接url地址，实际上由于只会上传一个文件，对应的只生成一个url地址，所以拼接无影响
            row.jumpLink = uploadFileUrlArr
                .map((e = new AdvertFileUploadUrl()) => {
                    return e.advertFileUrl;
                })
                .join('');
        },
        changeUploadFiles(uploadFileArr, uploadFileUrlArr) {
            this.advert.uploadFiles = uploadFileArr;
            //拼接url地址，实际上由于只会上传一个文件，对应的只生成一个url地址，所以拼接无影响
            this.advert.uploadAccessory = uploadFileUrlArr
                .map((e = new AdvertFileUploadUrl()) => {
                    return e.advertFileUrl;
                })
                .join('');
        },
        isImgAdvertFileType() {
            return AdvertUtils.isImgAdvertFileType(this.advert.type);
        },
        changeJumpType(advert) {
            //切换跳转类型
            advert.jumpLink = '';
            advert.jumpFiles = [];
        },
        isVideoAdvertFileType() {
            return AdvertUtils.isVideoAdvertFileType(this.advert.type);
        },
        handleSave() {
            this.$refs.updateForm.validate(async (valid) => {
                if (!valid) {
                    this.$message.error('填写内容有误，请检查所填写的所有数据');
                    return false;
                }
                await this.$efApi.advertManageApi.update(this.advert.code, {
                    name: this.advert.name,
                    customerCode: this.advert.customerCode,
                    type: this.advert.type,
                    uploadAccessory: this.advert.uploadAccessory,
                    content: this.advert.content,
                    timeLength: this.advert.timeLength,
                    jumpType: this.advert.jumpType,
                    jumpLink: this.advert.jumpLink,
                });
                this.goBackAndReload();
            });
        },
    },
};
</script>
<style scoped>
.image,
.video {
    width: 800px;
    height: 600px;
    display: block;
    margin: 0 auto;
    padding: 0;
}
</style>
